
*
{
    margin: 0;
    padding: 0;   
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html,
body
{
    overflow: hidden;
    outline: none;
    border: none;
}

@font-face
{
    font-family: "al";
    src: url(/static/font/al.woff2);
    
}

a, a:hover, a:focus, a:active 
{
    text-decoration: none;
    color:inherit;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.loading
{
    /* margin: auto; */
    position: absolute;
    top: 46%;
    left: 46.5%;
    text-align: left;
    font-size: 50px;
    font-family: "al", Helvetica, sans-serif ;
    color:#fff;
    transition: transform 0.5s;
   
}

.loading.ended
{
    color:rgba( 1, 1, 1, 0);
    transition: color 0.5s ease-in-out;
}

.nav
{
    
    color: #fff;
    font-family: "al", Helvetica, sans-serif;
    letter-spacing: 0.3em;
    font-size: 12px;
    line-height: 20px;
    transition:color 500ms cubic-bezier(.25,.46,.45,.94)
  
}


.n1{
    position: absolute;
    top: 25px;
    left: 9vw;
    content:url(../static/images/dm_logo.png);
    width: 70px;
    height: 70px;
    
}


.n2 
{
    position: absolute;
    float:right;
    top: 50px;
    right: 10vw;
    
    
}

.n3 
{
    display: block;
    position: absolute;
    bottom: 50px;
    right: 10vw;
    text-align: right; 
   
}

.n4 
{
    position: absolute;
    float: right;
    bottom: 50px;
    left: 10vw;
    

}

/*
.grid{
    display: grid;
    grid-template: 1000px / repeat(12,144px);
    grid-column-gap: 16px;   
}

.column{
    background-color: rgba(0, 247, 255, 0.527);
}

.margin{
    width: 140px;
}

*/